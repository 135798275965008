/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import AddToCartPopup from 'Component/AddToCartPopup';
import CartIcon from 'Component/CartIcon';
import If from 'Component/If';
import {
    AddToCartComponent as SourceAddToCartComponent,
} from 'SourceComponent/AddToCart/AddToCart.component';
import { ReactElement } from 'Type/Common.type';

import { AddToCartComponentProps } from './AddToCart.type';

import './AddToCart.override.style';

/** @namespace Pwa/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCartComponent {
    props!: AddToCartComponentProps;

    render(): ReactElement {
        const {
            product,
            quantity,
            mix,
            layout,
            isDisabled,
            isAdding,
            isIconEnabled,
            handleButtonClick,
        } = this.props;

        return (
            <>
                <AddToCartPopup product={ product } quantity={ quantity } />
                <If condition={ Boolean(isIconEnabled) }>
                    <button
                      onClick={ handleButtonClick }
                      block="ProductAddToCart"
                      disabled={ isDisabled || isAdding }
                    >
                        <CartIcon />
                    </button>
                </If>
                <If condition={ !isIconEnabled }>
                    <button
                      onClick={ handleButtonClick }
                      block="Button AddToCart"
                      mix={ mix }
                      mods={ { layout } }
                      disabled={ isDisabled || isAdding }
                    >
                        { this.renderCartIcon() }
                        <span>{ isAdding ? __('Adding...') : __('Add to cart') }</span>
                    </button>
                </If>

            </>
        );
    }
}

export default AddToCartComponent;
