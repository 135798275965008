import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { FormattedMoney } from '@scandipwa/scandipwa/src/util/Product/Product.type';

import {
    ProductPriceComponent as SourceProductPriceComponent,
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/** @namespace Pwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPriceComponent {
    renderPriceWithOrWithoutTax(
        basePrice: Partial<FormattedMoney>,
        taxPrice: Partial<FormattedMoney>,
        label?: string | ReactElement,
    ): ReactElement {
        // ONLY INCLUDE TAX IN PRICE
        // if (tax_display_type === DisplayProductPricesInCatalog.INCL_TAX) {
        return this.renderPrice(basePrice, label);
        // }

        // if (tax_display_type === DisplayProductPricesInCatalog.EXCL_TAX) {
        //     return this.renderPrice(taxPrice, label);
        // }

        // return (
        //     <>
        //         { this.renderPrice(basePrice, label) }
        //         { this.renderSubPrice(taxPrice) }
        //     </>
        // );
    }
}

export default ProductPriceComponent;
